import cookieCommons from 'common/cookieCommons';
import { UseQueryOptions, useQuery } from 'react-query';
import { getProfile } from '../apis/profile.api';
import { USER_INFO } from '../constant/QueryKeyName';
import { IGetProfile, IUserProfileData } from '../interfaces/profile.interface';

export const generateGetProfileData = (
  defaultData?: Partial<IGetProfile>
): IGetProfile => ({
  accessToken:
    defaultData?.accessToken || cookieCommons.getAccessToken() || 'undefined', //use default token, or get from cookies, or empty string
  userId: defaultData?.userId ?? cookieCommons.getQbankUserId() ?? 'undefined',
  requesterUserId:
    defaultData?.requesterUserId || cookieCommons.getQbankUserId() || 'null',
});

export default function useProfile(
  data?: Partial<IGetProfile>,
  opt?: Omit<
    UseQueryOptions<IUserProfileData, Error, IUserProfileData, string[]>,
    'queryKey' | 'queryFn'
  >
) {
  const userId = data?.userId ?? cookieCommons.getQbankUserId();

  return useQuery(
    [USER_INFO, userId as string],
    async () => {
      const _data = generateGetProfileData(data);
      return (await getProfile(_data)).data.data as IUserProfileData;
    },
    {
      ...opt,
      enabled: opt?.enabled && !!userId,
    }
  );
}
