import UpgradeAccountButton from '@/components/buttons/UpgradeAccountButton';
import useGetLatestSubscription from '@/hooks/subscription/useGetLatestSubscription';
import {
  ESubscriptionDuration,
  hashSubscriptionDurationToDates,
  reversedHashSubscriptionDuration,
} from '@/interfaces/subscription.interface';
import {
  hasUserSubscripted,
  hasUserSubscriptedIdealPlan,
} from 'common/subscription/checkSubscriptionResource';
import { Icon } from 'design-system/components/Icon';
import ProgressIndicator from 'design-system/components/ProgressIndicator/ProgressIndicator';
import { Typography } from 'design-system/components/Typography';
import moment from 'moment';
import { useMemo } from 'react';

const hashUnitLabel = {
  d: 'ngày',
  h: 'giờ',
  m: 'phút',
};

const RemainingSubscriptionSection = ({
  displayUpdateButton,
  expanded = true,
}: {
  displayUpdateButton?: boolean;
  expanded?: boolean;
}) => {
  const { data } = useGetLatestSubscription({});

  const remainingTimes: {
    remainingDays: number;
    text: string;
    unit: 'd' | 'h' | 'm';
  } = useMemo(() => {
    let text = `1`;

    const remainingDays =
      moment(data?.subscription?.endDate).diff(moment(), 'day', true) + 7 / 24; // HaNoi timezone offset in days

    if (remainingDays >= 1) {
      return {
        remainingDays,
        text: `${Math.round(remainingDays)}`,
        unit: 'd',
      };
    }

    const remainingHours = remainingDays * 24; //24 hours per day
    if (remainingHours >= 1) {
      return {
        remainingDays,
        text: `${Math.round(remainingHours)}`,
        unit: 'h',
      };
    }

    const remainingMinutes = remainingHours * 60; //60 minutes per hour
    if (remainingMinutes >= 1) {
      return {
        remainingDays,
        text: `${Math.round(remainingMinutes)}`,
        unit: 'm',
      };
    }

    return {
      remainingDays,
      text,
      unit: 'm',
    };
  }, [data?.subscription]);

  return (
    <div className="w-full flex-shrink-0 items-center space-y-3">
      {hasUserSubscripted(data) && (
        <div className="space-y-2 rounded-lg border border-Other/Outlinedborder-stroke p-2">
          {expanded && (
            <>
              <div className="flex items-center gap-1 text-Primary/Main">
                <Icon iconName="blink-blink" />
                <Typography variant="button3">
                  Tài khoản {data?.subscription?.packageName}
                </Typography>
              </div>
              <ProgressIndicator
                value={
                  (remainingTimes.remainingDays / //use remainingDays because the total duration of any packages is measured by days
                    hashSubscriptionDurationToDates[
                      reversedHashSubscriptionDuration[
                        data?.subscription
                          ?.durationName as ESubscriptionDuration
                      ]
                    ]) *
                  100
                }
                type={'track'}
              />
            </>
          )}
          <div
            className={`flex items-center gap-1 text-Text/Lightbackground/Secondary ${
              expanded ? '' : 'flex-col'
            }`}
          >
            <Icon iconName="clock" size={16} />
            <Typography variant="input-label">
              {expanded
                ? `Còn ${remainingTimes.text} ${
                    hashUnitLabel[remainingTimes.unit]
                  } sử dụng`
                : `${remainingTimes.text}${remainingTimes.unit}`}
            </Typography>
          </div>
        </div>
      )}

      {!hasUserSubscriptedIdealPlan(data) && displayUpdateButton && (
        <UpgradeAccountButton className="w-full" disableChangeWhenPackageActive>
          Nâng cấp tài khoản
        </UpgradeAccountButton>
      )}
    </div>
  );
};

export default RemainingSubscriptionSection;
