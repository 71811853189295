import {
  DOCCEN_AI_TYPO_BETA,
  DOCCEN_AI_TYPO_ICON,
  DOCCEN_TYPO,
} from '@/constant/imgImport';
import {
  aiTextStyles,
  getClassNames,
  getLogoPath,
  getLogoSizes,
} from './helpers';
import { DoccenLogoProps } from './types';

const DoccenLogo = (props: DoccenLogoProps) => {
  const {
    size = 'small',
    department = 'doccen-ai',
    iconOnly = true,
    className,
    custom,
    ...restProps
  } = props;
  const { logo, typo, typoAI } = getLogoSizes(size, department, iconOnly);
  const isDoccenAI = department === 'doccen-ai';

  const { classNameAI, sizeAI } = aiTextStyles[size];

  const finalClassName = `${getClassNames(
    size,
    department,
    iconOnly
  )} ${className}`;

  return (
    <div {...restProps} className={finalClassName}>
      <img
        src={getLogoPath(department)}
        style={{
          width: custom?.logo?.width || logo.width,
          height: custom?.logo?.height || logo.height,
        }}
      />
      {!iconOnly && (
        <div
          className={`flex flex-col ${
            isDoccenAI ? 'justify-between' : 'justify-center'
          }`}
        >
          {isDoccenAI && (
            <img
              src={DOCCEN_AI_TYPO_BETA}
              style={{
                width: custom?.typoAI?.width || sizeAI[0],
                height: custom?.typoAI?.height || sizeAI[1],
              }}
              className={classNameAI}
            />
          )}
          <div className="flex space-x-[1px]">
            <img
              src={DOCCEN_TYPO}
              style={{
                width: custom?.typo?.width || typo.width,
                height: custom?.typo?.height || typo.height,
              }}
            />
            {isDoccenAI && (
              <img
                src={DOCCEN_AI_TYPO_ICON}
                style={{
                  width: custom?.typoAI?.width || typoAI.width,
                  height: custom?.typoAI?.height || typoAI.height,
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DoccenLogo;
