import { PATH_NAME } from 'configs/pathName';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useScreen } from '../useScreen';

const useHideHeaderInPostDetail = () => {
  const [show, setShow] = useState(true);
  const router = useRouter();

  const { isDesktop } = useScreen();

  useEffect(() => {
    if (!isDesktop && router.pathname === PATH_NAME.HOME_FEED_POST_DETAIL) {
      let prePos = window.scrollY;

      const scroll = () => {
        setShow(window.scrollY < prePos || window.scrollY <= 0);
        prePos = window.scrollY;
      };

      document.addEventListener('scroll', scroll);

      return () => {
        document.removeEventListener('scroll', scroll);
      };
    }
  }, [isDesktop, router.pathname]);

  return show;
};

export default useHideHeaderInPostDetail;
