import { USER_PLAN } from '@/constant/storage';
import { SearchStatusFollowEnum } from '@/interfaces/notification.interface';
import { doccenHTTPRequest } from 'services/axios/httpRequest';
import {
  ICertificateInput,
  IContact,
  IGetProfile,
  IGetSlug,
  IGetUidAndSlug,
  IRewardInput,
  IScientificPaperInput,
  ISocialLink,
  IUserCover,
  IUserFullName,
  IUserIntroduction,
  IUserSExp,
  IUserSchool,
  IUserSlug,
  PageMeta,
} from '../interfaces/profile.interface';
import {
  IUserAvt,
  IUserProject,
  IUserVolunteeringActivity,
} from './../interfaces/profile.interface';

export const getSocialLink = (socialLinkId: string | undefined | string[]) => {
  return doccenHTTPRequest.get(`/profile/social/${socialLinkId}`);
};

export const postSocialLink = (data: ISocialLink) => {
  return doccenHTTPRequest.post('/profile/social', data);
};

export const putSocialLink = (
  data: ISocialLink,
  socialLinkId: string | string[] | undefined
) => {
  return doccenHTTPRequest.put(`/profile/edit-social/${socialLinkId}`, data);
};

export const deleteSocialLink = (
  socialLinkId: string | undefined | string[]
) => {
  return doccenHTTPRequest.delete(`/profile/delete-social/${socialLinkId}`);
};

export const getProfile = (data: IGetProfile) => {
  return doccenHTTPRequest.get(
    `/profile/${data.userId}?accessToken=${data.accessToken}&userId=${data.requesterUserId}`
  );
};

export const postScientificPaper = (data: IScientificPaperInput) => {
  return doccenHTTPRequest.post('/profile/scientific', data);
};

export const deleteScientificPaper = (scientificId: string | undefined) => {
  return doccenHTTPRequest.delete(`/profile/delete-scientific/${scientificId}`);
};

export const putScientificPaper = (
  data: IScientificPaperInput,
  scientificId: string | undefined
) => {
  return doccenHTTPRequest.put(
    `/profile/edit-scientific/${scientificId}`,
    data
  );
};

export const postReward = (data: IRewardInput) => {
  return doccenHTTPRequest.post('/profile/reward', data);
};

export const deleteReward = (rewardId: string | undefined) => {
  return doccenHTTPRequest.delete(`/profile/delete-reward/${rewardId}`);
};

export const putReward = (data: IRewardInput, rewardId: string | undefined) => {
  return doccenHTTPRequest.put(`/profile/edit-reward/${rewardId}`, data);
};

export const postProject = (data: IUserProject) => {
  return doccenHTTPRequest.post('/profile/project', data);
};

export const putProject = (
  data: IUserProject,
  projectId: string | undefined
) => {
  return doccenHTTPRequest.put(`/profile/edit-project/${projectId}`, data);
};

export const deleteProject = (projectId: string | undefined) => {
  return doccenHTTPRequest.delete(`/profile/delete-project/${projectId}`);
};

export const postVolunteeringActivity = (data: IUserVolunteeringActivity) => {
  return doccenHTTPRequest.post('/profile/volunteering', data);
};

export const putVolunteeringActivity = (
  data: IUserVolunteeringActivity,
  volunteerId: string | undefined
) => {
  return doccenHTTPRequest.put(
    `/profile/edit-volunteering/${volunteerId}`,
    data
  );
};

export const deleteVolunteeringActivity = (volunteerId: string | undefined) => {
  return doccenHTTPRequest.delete(
    `/profile/delete-volunteering/${volunteerId}`
  );
};

export const postCertificate = (data: ICertificateInput) => {
  return doccenHTTPRequest.post('/profile/certificate', data);
};

export const deleteCertificate = (certificateId: string | undefined) => {
  return doccenHTTPRequest.delete(
    `/profile/delete-certificate/${certificateId}`
  );
};

export const putCertificate = (
  data: ICertificateInput,
  certificateId: string | undefined
) => {
  return doccenHTTPRequest.put(
    `/profile/edit-certificate/${certificateId}`,
    data
  );
};

export const postContactInfo = (data: IContact) => {
  return doccenHTTPRequest.post(`/profile/contact`, data);
};

export const deleteContactInfo = (contactId: string | undefined) => {
  return doccenHTTPRequest.delete(`/profile/delete-contact/${contactId}`);
};

export const putContactInfo = (
  data: IContact,
  contactId: string | undefined
) => {
  return doccenHTTPRequest.put(`/profile/edit-contact/${contactId}`, data);
};

export const getFollowList = (userId: string | string[] | undefined) => {
  return doccenHTTPRequest.get(
    `/profile/follow/getFollowList?userId=${userId}`
  );
};
export const getNotiList = (
  { page, limit }: Partial<PageMeta> = { page: 1, limit: 10 }
) => {
  return doccenHTTPRequest.get(`/notification/all?page=${page}&limit=${limit}`);
};

export const postExperience = async (data: IUserSExp) => {
  return doccenHTTPRequest.post(`/profile/experience`, data);
};

export const putExperience = async (data: IUserSExp, experienceId: string) => {
  return doccenHTTPRequest.put(
    `/profile/edit-experience/${experienceId}`,
    data
  );
};

export const deleteExperience = async (experienceId: string) => {
  return doccenHTTPRequest.delete(`/profile/delete-experience/${experienceId}`);
};

export const postSchool = async (data: IUserSchool) => {
  return doccenHTTPRequest.post(`/profile/school`, data);
};

export const putSchool = async (data: IUserSchool, schoolId: string) => {
  return doccenHTTPRequest.put(`/profile/edit-school/${schoolId}`, data);
};

export const deleteSchool = async (schoolId: string) => {
  return doccenHTTPRequest.delete(`/profile/delete-school/${schoolId}`);
};

export const putIntroduction = async (data: IUserIntroduction) => {
  return doccenHTTPRequest.put(`/profile/edit-profile`, data);
};

export const postName = async (data: IUserFullName) => {
  return doccenHTTPRequest.post(`/profile/quick-start`, data);
};

export const postUserAvatar = async (data: IUserAvt) => {
  return doccenHTTPRequest.put('/profile/avatar', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const putUserCover = async (data: IUserCover) => {
  console.log('put');
  return doccenHTTPRequest.put('/profile/cover', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getSlugByUid = async (data: IGetSlug) => {
  return doccenHTTPRequest.get(`/profile/slug?accessToken=${data.accessToken}`);
};

export const updateSlug = async (data: IUserSlug) => {
  return doccenHTTPRequest.patch('/profile/slug', data);
};

export const checkSlugAvailable = async (data: IUserSlug) => {
  return doccenHTTPRequest.get(`/profile/slug-available?slug=${data.slug}`);
};

export const getUidAndSlug = async (data: IGetUidAndSlug) => {
  return doccenHTTPRequest.get(
    `/profile/userId-slug?uid_slug=${data.uid_slug}`
  );
};

export const deleteSlug = async () => {
  return doccenHTTPRequest.delete('/profile/slug');
};

export const editTitle = async (data: { title: string }) => {
  return doccenHTTPRequest.put('/profile/edit-title', data);
};

export const getRecommendUserInEnterpriseProfile = async (
  enterpriseId: string
) =>
  doccenHTTPRequest
    .get(`/profile/enterprise/${enterpriseId}/related`)
    .then((res) => res.data) as Promise<
    {
      email: string;
      fullName: string;
      avatar: string;
      cover: string;
      ownerId: number;
      hasBlueTick: boolean;
      userPlan: USER_PLAN;
      followStatus: SearchStatusFollowEnum;
      academicRank: string;
    }[]
  >;

export const uploadFileProfileEnterprice = async (
  file: File
): Promise<{
  data: {
    url: string;
    createdAt: string;
  };
  message: string;
  statusCode: number;
}> => {
  const data = new FormData();

  data.append('enterprise-file', file);
  return doccenHTTPRequest
    .post('/profile/enterprise/file', data)
    .then((res: any) => res);
};

export const putConfigurationEnterpriseJson = async (json: {
  configuration: JSON;
}) => doccenHTTPRequest.put('/profile/enterprise/configuration', json);
