import { RibbonBadge } from 'design-system/components/Badge';
import { Button } from 'design-system/components/Button';
import DoccenLogo from 'design-system/components/Images/DoccenLogo/DoccenLogo';
import { Tooltip } from 'design-system/components/Tooltips';
import { Typography } from 'design-system/components/Typography';
import { twMerge } from 'tailwind-merge';
import { openAiPage } from './helper';

const AiButtonDesktop = ({ expanded }: { expanded: boolean }) => {
  return (
    <Tooltip title={expanded ? undefined : 'DoccenAI'}>
      <Button
        onClick={openAiPage}
        size="large"
        emphasis="medium"
        className={twMerge(
          'relative -mx-1 gap-2 rounded-[100px_20px_100px_100px] border border-Primary/Border bg-DoccenAI/Background/Blue p-[8px_12px]',
          expanded ? 'w-full' : 'aspect-square'
        )}
      >
        <DoccenLogo
          size={'small'}
          department="doccen-ai"
          iconOnly={true}
          className="flex-shrink-0"
        />
        {expanded && (
          <Typography variant="button1" className="truncate text-Primary/Main">
            DoccenAI
          </Typography>
        )}

        <RibbonBadge className="absolute -right-[1px] -top-2 z-[2]">
          Mới
        </RibbonBadge>
      </Button>
    </Tooltip>
  );
};

export default AiButtonDesktop;
