import { generateQuery } from '@/helpers/pathName';
import useProfile from '@/hooks/useProfile';
import { useScreen } from '@/hooks/useScreen';
import useToggle from '@/hooks/useToggle';
import cookieCommons from 'common/cookieCommons';
// import { EVENT_URL } from 'configs/externalLink';
import { ExpiredSubscriptionManualHOC } from '@/components/elements/subscription/ExpiredSubscriptionHOC';
import { PATH_NAME } from 'configs/pathName';
import { Divider } from 'design-system/components/Divider';
import { NavList, Navigations } from 'design-system/components/Navigations';
import { useRouter } from 'next/router';
import { useCallback, useContext, useMemo, useState } from 'react';
import AuthContext from 'state/auth/AuthContext';
import RemainingSubscriptionSection from '../components/elements/subscription/RemainingSubscriptionSection';
import { DEFAULT_AVATAR } from '../constant';
import BaseLayoutTopNavigations from './components/BaseLayoutTopNavigations';
import AiButtonDesktop from './components/ai/AIButtonDesktop';
import AiButtonMobile from './components/ai/AiButtonMobile';

const BaseLayout = ({
  children,
  hideBottomBar = false,
  hideTopNavOnScroll = false,
}: {
  children: React.ReactNode | React.ReactNode[];
  hideBottomBar?: boolean;
  hideTopNavOnScroll?: boolean;
}) => {
  const router = useRouter();
  const { isLoggedIn } = useContext(AuthContext);
  const [expanded, toggleExpand] = useToggle();
  const [openLoginModal, toggleLoginModal] = useToggle();
  const [cachedPath, setCachedPath] = useState<string>('');

  const { isDesktop } = useScreen();

  const { data: userProfileData } = useProfile(
    {},
    {
      enabled: isLoggedIn(),
    }
  );
  const userAvatar = userProfileData?.profiles.avatar || DEFAULT_AVATAR;

  const isSelectedLogo: boolean = useMemo(() => {
    return router.pathname === PATH_NAME.EXTEND;
  }, [router.pathname]);

  const MENU_ITEMS: Array<NavList> = useMemo(() => {
    const menuItems: Array<NavList> = [
      {
        id: '1',
        startAdornment: <Divider className="my-3" />,
        navItems: [
          {
            id: '1.1',
            icon: 'library-active',
            name: 'Thư viện',
            url: PATH_NAME.LIBRARY,
            onClick: () => router.push(PATH_NAME.LIBRARY),
          },

          {
            id: '1.3',
            icon: 'discover',
            name: 'Khám phá',
            url: isLoggedIn() ? PATH_NAME.HOME_FEED : PATH_NAME.FEED,
            onClick: () =>
              isLoggedIn()
                ? router.push(PATH_NAME.HOME_FEED)
                : router.push(PATH_NAME.FEED),
          },
          // {
          //   id: '1.3',
          //   icon: 'event',
          //   name: 'Sự kiện',
          //   url: EVENT_URL,
          //   onClick: () => window.open(EVENT_URL, '_blank'),
          // },
          {
            id: '1.4',
            icon: 'profile-2user',
            name: 'Cộng đồng',
            url: PATH_NAME.MY_GROUPS,
            selectedChild: true,
            onClick: () => handleClick(PATH_NAME.MY_GROUPS),
          },
        ],
      },
    ];

    if (!isDesktop) {
      menuItems[0].navItems.unshift({
        id: 'ai.btn.doccenAI',
        icon: 'ai-icon',
        name: '',
        url: PATH_NAME.AI,
        onClick() {
          window.open(PATH_NAME.AI, '_blank');
        },
        renderChild() {
          return <AiButtonMobile key="AiButtonMobile" />;
        },
      });
    } else {
      menuItems.push({
        id: '2',
        startAdornment: <Divider className="my-3" />,
        navItems: [
          {
            id: '2.2',
            icon: 'setting',
            name: 'Cài đặt',
            url: PATH_NAME.SETTING,
            onClick: () => handleClick(PATH_NAME.SETTING),
          },
        ],
      });

      menuItems[0].navItems.splice(1, 0, {
        id: '1.2',
        icon: 'elearning',
        name: 'Khoá học',
        url: PATH_NAME.COURSES,
        onClick: () => handleClick(PATH_NAME.COURSES),
      });

      menuItems.unshift({
        id: 'ai',
        startAdornment: <Divider className="my-3" />,
        navItems: [
          {
            id: 'ai.btn.doccenAI',
            icon: 'ai-icon',
            name: '',
            url: PATH_NAME.AI,
            onClick() {
              window.open(PATH_NAME.AI, '_blank');
            },
            renderChild() {
              return (
                <AiButtonDesktop key="AiButtonDesktop" expanded={expanded} />
              );
            },
          },
        ],
      });
    }

    return menuItems;
  }, [isDesktop, isLoggedIn, expanded]);

  const handleClick = (pathName: string) => {
    setCachedPath(pathName);
    if (!isLoggedIn()) return toggleLoginModal();
    return router.push(pathName);
  };

  const handleCLickAvatar = useCallback(() => {
    if (!isDesktop) {
      router.push(PATH_NAME.EXTEND);
    } else {
      if (isLoggedIn()) {
        router.push({
          pathname: PATH_NAME.PROFILE,
          query: {
            uid: cookieCommons.getQbankUserId(),
          },
        });
      } else {
        router.push({
          pathname: PATH_NAME.LOGIN,
          query: generateQuery(router),
        });
      }
    }
  }, [isDesktop, isLoggedIn]);

  return (
    <>
      <main className="flex min-h-screen pb-16 xl:pb-0">
        <Navigations
          hideBottomBar={hideBottomBar}
          expanded={expanded}
          onExpand={toggleExpand}
          navLists={MENU_ITEMS}
          logo={userAvatar}
          onClickLogo={handleCLickAvatar}
          isLogoSelected={isSelectedLogo}
          bottomSection={
            <RemainingSubscriptionSection
              displayUpdateButton={expanded}
              expanded={expanded}
            />
          }
          {...(!isDesktop ? {} : { className: 'sticky top-0' })}
        />

        <section className="w-full bg-Primary/Background xl:max-w-[calc(100vw-64px)]">
          <BaseLayoutTopNavigations
            hideOnScroll={hideTopNavOnScroll}
            openLoginModal={openLoginModal}
            toggleLoginModal={toggleLoginModal}
            cachedPath={cachedPath}
            setCachedPath={setCachedPath}
          />
          <div className="mx-auto max-w-container">{children}</div>
        </section>
      </main>

      <ExpiredSubscriptionManualHOC />
    </>
  );
};

export default BaseLayout;
