import RibbonBadge from 'design-system/components/Badge/RibbonBadge';
import DoccenLogo from 'design-system/components/Images/DoccenLogo/DoccenLogo';
import { openAiPage } from './helper';

const AiButtonMobile = () => {
  return (
    <>
      <div
        onClick={openAiPage}
        className="group relative z-0 flex-shrink-0 rounded-full bg-DoccenAI/Background/Blue p-[1px] transition-all ease-out active:scale-[90%] active:bg-none"
      >
        <div className="relative z-[3] h-[40px] w-[40px] cursor-pointer rounded-full bg-Other/White p-[10px_10px_6px] group-active:bg-Action/DC/Selected">
          <DoccenLogo
            size={'small'}
            department="doccen-ai"
            iconOnly={true}
            className="flex-shrink-0"
            custom={{
              logo: {
                width: 20,
                height: 20,
              },
            }}
          />
        </div>
        <RibbonBadge className="absolute -top-[3px] right-[2px] z-[5]">
          Mới
        </RibbonBadge>
      </div>

      {/* {open && <AiModalMobile open={open} toggle={toggle} />} */}
    </>
  );
};

export default AiButtonMobile;
