import { USER_PLAN } from '@/constant/storage';

export const checkAboveUnverified = (userPlan: USER_PLAN | undefined) => {
  return (
    userPlan === USER_PLAN.Business ||
    userPlan === USER_PLAN.Enterprise ||
    userPlan === USER_PLAN.Verified
  );
};

export const checkHighEndPlan = (userPlan: USER_PLAN | undefined) => {
  return userPlan === USER_PLAN.Business || userPlan === USER_PLAN.Enterprise;
};
