import { DOCCEN_AI_LOGO_ICON, DOCCEN_LOGO_ICON } from '@/constant/imgImport';
import { DoccenLogoDepartment, DoccenLogoSize, LogoSizes } from './types';

export const getLogoSizes = (
  size: DoccenLogoSize,
  department: 'doccen' | 'doccen-ai',
  iconOnly: boolean
): LogoSizes => {
  const defaultSize: LogoSizes = {
    logo: { width: 0, height: 0 },
    typo: { width: 0, height: 0 },
    typoAI: { width: 0, height: 0 },
  };

  const hashSize: {
    [key in 'doccen' | 'doccen-ai']: {
      [key in 'true' | 'false']: {
        [key in DoccenLogoSize]: LogoSizes;
      };
    };
  } = {
    doccen: {
      false: {
        small: {
          logo: { width: 12, height: 20 },
          typo: { width: 41, height: 10 },
          typoAI: { width: 0, height: 0 },
        },
        medium: {
          logo: { width: 21, height: 36 },
          typo: { width: 72, height: 17 },
          typoAI: { width: 0, height: 0 },
        },
        large: {
          logo: { width: 41, height: 71 },
          typo: { width: 147, height: 35 },
          typoAI: { width: 0, height: 0 },
        },
        'extra-large': {
          logo: { width: 41, height: 71 },
          typo: {
            width: 147,
            height: 35,
          },
          typoAI: defaultSize['typoAI'],
        },
      },
      true: {
        small: {
          logo: { width: 24, height: 24 },
          typo: defaultSize['typo'],
          typoAI: defaultSize['typoAI'],
        },
        medium: {
          logo: { width: 32, height: 32 },
          typo: defaultSize['typo'],
          typoAI: defaultSize['typoAI'],
        },
        large: defaultSize,
        'extra-large': defaultSize,
      },
    },
    'doccen-ai': {
      false: {
        small: defaultSize,
        medium: {
          logo: { width: 20, height: 28 },
          typo: { width: 55, height: 13 },
          typoAI: { width: 15, height: 12 },
        },
        large: {
          logo: { width: 36, height: 51 },
          typo: { width: 107, height: 25 },
          typoAI: { width: 31, height: 25 },
        },
        'extra-large': {
          logo: { width: 54, height: 76 },
          typo: { width: 161, height: 38 },
          typoAI: { width: 46, height: 38 },
        },
      },
      true: {
        small: {
          logo: { width: 24, height: 24 },
          typo: defaultSize['typo'],
          typoAI: defaultSize['typoAI'],
        },
        medium: {
          logo: { width: 32, height: 32 },
          typo: defaultSize['typo'],
          typoAI: defaultSize['typoAI'],
        },
        large: defaultSize,
        'extra-large': defaultSize,
      },
    },
  };

  return hashSize[department][`${iconOnly}`][size];
};

export const getLogoPath = (department: DoccenLogoDepartment) => {
  switch (department) {
    case 'doccen':
      return DOCCEN_LOGO_ICON;
    case 'doccen-ai':
      return DOCCEN_AI_LOGO_ICON;
    default:
      return '';
  }
};

export const getClassNames = (
  size: DoccenLogoSize,
  department: DoccenLogoDepartment,
  iconOnly: boolean
): string => {
  if (size === 'extra-large' && department === 'doccen' && !iconOnly) {
    return 'flex space-x-2';
  }

  if (size === 'medium' && department === 'doccen' && !iconOnly) {
    return 'flex space-x-2';
  }

  if (size === 'small' && department === 'doccen' && !iconOnly) {
    return 'flex space-x-2';
  }

  if (size === 'extra-large' && department === 'doccen-ai' && !iconOnly) {
    return 'flex px-2 py-3 space-x-[18px]';
  }

  if (size === 'large' && department === 'doccen-ai' && !iconOnly) {
    return 'flex px-1 space-x-3';
  }

  if (size === 'medium' && department === 'doccen-ai' && !iconOnly) {
    return 'flex px-[2px] py-[5px] space-x-[6px]';
  }

  return '';
};

export const aiTextStyles: Record<
  DoccenLogoSize,
  {
    classNameAI: string;
    sizeAI: [number, number];
  }
> = {
  'extra-large': {
    classNameAI: 'mb-4 ml-auto',
    sizeAI: [54, 21],
  },
  medium: {
    classNameAI: 'mb-[6px] ml-auto',
    sizeAI: [26, 9],
  },
  small: {
    classNameAI: 'mb-2 ml-auto',
    sizeAI: [18, 6],
  },
  large: {
    classNameAI: 'mb-1 ml-auto',
    sizeAI: [38, 15],
  },
};
