import dynamic from 'next/dynamic';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { ProgressIndicatorProps } from './types';

const LinearProgress = dynamic(() => import('@mui/material/LinearProgress'));
const CircularProgress = dynamic(
  () => import('@mui/material/CircularProgress')
);

const ProgressIndicator = forwardRef<HTMLSpanElement, ProgressIndicatorProps>(
  function ProgressIndicator(props: ProgressIndicatorProps, ref) {
    const { size = '44', type, value, color, sx, ...spanProps } = props;

    const renderProgress = () =>
      type === 'circular' ? (
        <CircularProgress
          ref={ref}
          {...spanProps}
          className={twMerge('!text-Primary/Main', spanProps.className)}
          disableShrink
          value={value}
          variant={Number.isInteger(value) ? 'determinate' : 'indeterminate'}
          size={parseInt(size)}
          thickness={4}
          sx={{
            '& .MuiCircularProgress-circle': {
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            },
            ...sx,
          }}
        />
      ) : (
        <LinearProgress
          ref={ref}
          {...spanProps}
          className={twMerge('rounded-[2px]', spanProps.className)}
          value={value}
          variant={Number.isInteger(value) ? 'determinate' : 'indeterminate'}
          sx={{
            backgroundColor: `rgba(0, 159, 228, 0.2)`,
            '& .MuiLinearProgress-bar': {
              borderRadius: '2px',
              backgroundColor: '#009CE0',
            },
            ...sx,
          }}
        />
      );

    return renderProgress();
  }
);

export default ProgressIndicator;
