import { DEFAULT_AVATAR } from '@/constant/avatar';
import { checkAboveUnverified } from '@/helpers/checkUserPlan';
import { generateQuery } from '@/helpers/pathName';
import useHideHeaderInPostDetail from '@/hooks/post/useHideHeaderInPostDetail';
import useProfile from '@/hooks/useProfile';
import { useScreen } from '@/hooks/useScreen';
import useToggle from '@/hooks/useToggle';
import { Slide } from '@mui/material';
import { logout } from 'common/authFunction';
import cookieCommons from 'common/cookieCommons';
import {
  DOCCEN_FACEBOOK,
  DOCCEN_TIKTOK,
  DOCCEN_ZALO_CONTACT_URL,
} from 'configs/externalLink';
import { PATH_NAME } from 'configs/pathName';
import { RolesBadge } from 'design-system/components/Badge';
import { ExtendCardProps } from 'design-system/components/Cards';
import {
  TopNavigationProps,
  TopNavigations,
} from 'design-system/components/Navigations';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import AuthContext from 'state/auth/AuthContext';

const NotificationSectionModal = dynamic(
  () => import('@/components/modal/notifications/NotificationSectionModal')
);

const LoginModal = dynamic(() => import('@/components/modal/LoginModal2'));

const BaseLayoutTopNavigations = ({
  openLoginModal,
  toggleLoginModal,
  cachedPath,
  setCachedPath,
  hideOnScroll = false,
}: {
  openLoginModal: boolean;
  toggleLoginModal: () => void;
  cachedPath: string;
  setCachedPath: Dispatch<SetStateAction<string>>;
  hideOnScroll?: boolean;
}) => {
  const router = useRouter();
  const { isLoggedIn } = useContext(AuthContext);
  const [expandNotification, toggleExpandNotification] = useToggle();
  const [searchValue, setSearchValue] = useState<string>(
    (router.query.searchKey as string) ?? ''
  );

  const { isDesktop } = useScreen();

  const { data: userProfileData } = useProfile(
    {},
    {
      enabled: isLoggedIn(),
    }
  );
  const userAvatar = userProfileData?.profiles.avatar || DEFAULT_AVATAR;
  const academicTitle = userProfileData?.profiles.title || '';
  const nameUser = userProfileData?.profiles.qbankData.fullName || 'Người dùng';

  const listExtandCards: Array<ExtendCardProps> = (() => {
    const list: Array<ExtendCardProps> = [
      {
        iconName: 'security-user',
        badgeContent: <RolesBadge type="premium" className="h-[21px]" />,
        title: 'Xác thực tài khoản',
        url: PATH_NAME.VERIFY_ACCOUNT,
      },
      {
        iconName: 'scan-barcode',
        title: 'Mã QR',
        onClick: () => handleClick(PATH_NAME.SCAN_QR),
      },
      {
        iconName: 'term-&-policies',
        title: 'Điều khoản',
        url: PATH_NAME.POLICY,
      },
    ];

    if (checkAboveUnverified(userProfileData?.profiles.userPlan)) {
      list.splice(0, 1);
    }

    return list;
  })();

  const handleClickTopNav: TopNavigationProps['handleClick'] = useMemo(() => {
    return {
      notifications: () =>
        isDesktop
          ? toggleExpandNotification()
          : router.push(PATH_NAME.NOTIFICATION),
      'sign-in': toggleLoginModal,
      'sign-up': () =>
        router.push({
          pathname: PATH_NAME.REGISTER,
          query: generateQuery(router),
        }),
      'log-out': logout,
      profile: () =>
        router.push({
          pathname: PATH_NAME.PROFILE,
          query: {
            uid: cookieCommons.getQbankUserId(),
          },
        }),
      tiktok: () => window.open(DOCCEN_TIKTOK, '_blank'),
      facebook: () => window.open(DOCCEN_FACEBOOK, '_blank'),
      support: () => router.push(DOCCEN_ZALO_CONTACT_URL),
    };
  }, [isDesktop]);

  const handleClick = (pathName: string) => {
    setCachedPath(pathName);
    if (!isLoggedIn()) return toggleLoginModal();
    return router.push(pathName);
  };

  const show = useHideHeaderInPostDetail();

  const topNav = (
    <TopNavigations
      type={isLoggedIn() ? 'signed-in' : 'visitor'}
      className="sticky top-0 z-50 bg-white"
      handleClick={handleClickTopNav}
      userInfo={{
        academicTitle,
        name: nameUser,
        avatar: userAvatar,
      }}
      listExtandCards={listExtandCards}
      search={{
        value: searchValue,
        onChange: setSearchValue,
        placeholder: 'Tìm kiếm',
      }}
    />
  );

  return (
    <>
      {hideOnScroll ? (
        <Slide direction="down" in={show}>
          {topNav}
        </Slide>
      ) : (
        topNav
      )}

      {isDesktop && expandNotification && (
        <NotificationSectionModal
          expand={expandNotification}
          onToggleExpand={toggleExpandNotification}
        />
      )}

      {openLoginModal && (
        <LoginModal
          open={openLoginModal}
          loginGoogleDisableRedirect={false}
          setOpen={() => {
            toggleLoginModal();
            setCachedPath('');
          }}
          {...(!!cachedPath && {
            successCb: () => window.open(cachedPath, '_self'),
          })}
        />
      )}
    </>
  );
};

export default BaseLayoutTopNavigations;
